import { graphql } from "gatsby";
import React from "react";
import GuestEnquiry from "../components/GuestEnquiry"

const Enquiry = ({ modules, parentId, location }) => {
  const components = {
    GuestEnquiry,
  };

  return (
    <>
      {modules
        ? modules
            .filter(
              (module) => !module?.hideSection || module?.hideSection === "no"
            )
            .map((module, i) => {
              const moduleName = module.__typename.replace(
                "WpPage_Pagecontent_PageContent_",
                ""
              );
              return (
                components[moduleName] &&
                React.createElement(components[moduleName], {
                  key: i,
                  module,
                  parentId,
                  location,
                })
              );
            })
        : null}
    </>
  );
};

export default Enquiry;
export const GuestEnquiryModulesFragment = graphql`
  fragment GuestEnquiryModulesFragment on WpPage {
    pageContent {
      pageContent {
        __typename
        ...GuestEnquiryFragment
    }
  }
}
`;
