import { graphql } from "gatsby";
import React, { useEffect } from "react";
import Enquiry from "../modules/Enquiry";
import { Helmet } from 'react-helmet';

const PageTemplate = (props) => {
  const modules = props?.data?.post?.pageContent?.pageContent;
  useEffect(() => {
    document.body.classList.remove("nav-menu-open");
    document.body.classList.remove("header-sticky");
    document.body.classList.remove("video-modal-open");
    document.body.classList.remove("blog-single-custom");
  }, []);

  return (
    <>
      <Helmet>
        <title>Guest Enquiry | Timeless Africa Safaris</title>
        <meta name="description" content="Dreaming of visiting Africa? Submit a travel enquiry and an expert member of our team will be in touch." />
        <link rel='canonical' href='https://www.tasafaris.com/guest-enquiry/' />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Enquiry modules={modules}/>
    </>
  )
}

export default PageTemplate;
export const formQuery = graphql`
  query formPageById($id: String!) {
    post: wpPage(id: { eq: $id }) {
      id
      title
      ...GuestEnquiryModulesFragment
    }
  }
`;